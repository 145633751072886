<template>
  <section class="dashboard--offer">
    <div class="container">
      <div class="row align-items-center flex-wrap-reverse">
        <div class="col-12 col-md-6">
          <div class="title--text">
            {{ $t('myListing.offerSection.title') }}
          </div>
          <div class="desc">
            {{ $t('myListing.offerSection.message') }}
          </div>
          <router-link to="/listing/add" class="btn btn-primary">
            {{ $t('myListing.offerSection.btn') }}
          </router-link>
        </div>
        <div class="col-12 col-md-6">
          <img src="@/assets/img/home/banner-3.svg" class="offer--image" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
