<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <dashboard-skeleton :page-loading="myBookingLoading">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ $t('dashboard.myBooking') }}
      </div>
      <div class="skeleton--container">
        <bookings-header />
        <v-tabs center-active v-if="categories && categories.length > 0">
          <v-tab
            :href="`#tab-${index}`"
            v-for="(category, index) in categories"
            :key="`category-title-${index}`"
            @click="changeCategory(category)"
          >
            {{ $t(`general.filter.${category.name}`) }}
          </v-tab>

          <v-tab-item
            :value="`tab-${index}`"
            v-for="(category, index) in categories"
            :key="`category-content-${index}`"
          >
            <pagination
              :push-state="false"
              :meta="myBookingMeta"
              @changePage="getBookings"
              :maxVisibleButtons="5"
            />
            <bookings />
            <pagination
              :push-state="false"
              :meta="myBookingMeta"
              @changePage="getBookings"
              :maxVisibleButtons="5"
            />
          </v-tab-item>
        </v-tabs>
      </div>
    </dashboard-skeleton>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import DashboardSkeleton from '@/components/dashboard/dashboard-skeleton';
import BookingsHeader from '@/components/dashboard/my-booking/bookings-header';
import Bookings from '@/components/dashboard/my-booking/bookings';
import DefaultHeadMixin from '@/mixins/defaultHead';
import { mapGetters, mapState } from 'vuex';

const Pagination = () => import('@/components/utils/fractal-pagination.vue');
export default {
  mixins: [DefaultHeadMixin],
  components: {
    Pagination,
    DefaultLayout,
    DashboardSkeleton,
    BookingsHeader,
    Bookings,
  },
  data() {
    return {
      checkBankAccountDone: false,
    };
  },
  computed: {
    ...mapState({
      categories: state => state.v2.booking.categoryNavList,
      myBookingLoading: state => state.v2.booking.actionLoading,
      myBookingMeta: state => state.v2.booking.meta,
    }),
    ...mapGetters({
      myBookingActiveCategory: 'v2/booking/activeCategory',
      myBookingActiveSortBy: 'v2/booking/activeSortBy',
    }),
    breadcrumbs() {
      const currentName = this.$t('dashboard.myBooking');
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: currentName,
          to: this.$router.currentRoute.path,
        },
      ];
    },
  },
  methods: {
    getBookings(page) {
      this.$store.dispatch('v2/booking/getBookingOrders', {
        page: page ? page : 1,
        category: this.myBookingActiveCategory,
        sortBy: this.myBookingActiveSortBy.name,
      });
    },
    close() {
      this.$store.commit('myListing/SET_ACTIVE_SIDEBAR', false);
    },
    async changeCategory(category) {
      await this.$store.dispatch('v2/booking/changeActiveCategory', category.name);
    },
  },
  async mounted() {
    this.$store.commit('myListing/SET_ACTIVE_SIDEBAR', true);
    // await this.$store.dispatch('v2/booking/getTotalBookingOrders');
    let query = this.$route.query;
    let page = query.page !== undefined ? query.page : 1;
    this.$nextTick(() => {
      setTimeout(async () => {
        await this.$store.dispatch('v2/booking/getBookingOrders', { page: page });
      }, 1);
    });
  },
};
</script>
